import Headroom from "headroom.js";

// Headroom: http://wicky.nillia.ms/headroom.js/
var nav = new Headroom(document.getElementById('site-header'), {
  tolerance: 10,
  offset: 40
});

nav.init();

document.querySelector('header #menu-icon').addEventListener('click', function() {
  document.querySelector('body').classList.toggle('frozen');
  document.querySelector('header nav').classList.toggle('open');
});

var navItems = document.querySelectorAll('#menu a');

navItems.forEach((item) => {
  item.addEventListener('click', function() {
    document.querySelector('body').classList.toggle('frozen');
    document.querySelector('header nav').classList.toggle('open');
  });
});
