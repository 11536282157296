import Vue from 'vue';
import VueAgile from 'vue-agile/dist/VueAgile.common';

let numberOfSlides = document.querySelectorAll('#carousel .slide').length;

if (document.querySelector('#carousel') !== null) {
  let app = new Vue({
    el: '#carousel',
    delimiters: ['${', '}'],
    data () {
      return {
        options: {
          autoplay: numberOfSlides == 1 ? false : true,
          autoplaySpeed: 10000,
          dots: numberOfSlides == 1 ? false : true,
          navButtons: false,
          timing: 'ease-in-out'
        }
      };
    },
    components: {
      agile: VueAgile,
    }
  });
}