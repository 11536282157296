import ScrollReveal from 'scrollreveal';

let heroElements = document.querySelectorAll('#hero h1, #hero .featured h2');
let opportunity = document.querySelectorAll('#opportunity, #opportunity .intro h2, #opportunity .intro p, #opportunity .card');
let whatWeDo = document.querySelectorAll('#what-we-do, #what-we-do .intro h2, #what-we-do .intro p, #what-we-do .card, #what-we-do .cta');
let howWeDoIt = document.querySelectorAll('#how-we-do-it, #how-we-do-it .intro h2, #how-we-do-it .intro p, #how-we-do-it .infographic, #how-we-do-it .card');
let featuredInsights = document.querySelectorAll('#featured-insights, #featured-insights .intro h2, #featured-insights .intro p, #featured-insights .card');
let testimonials = document.querySelectorAll('#testimonials, #testimonials .intro h2, #testimonials .intro p, #testimonials #carousel, #testimonials .logo');
let whyWeExist = document.querySelectorAll('#why-we-exist, #why-we-exist .intro h2, #why-we-exist .intro p, #why-we-exist .card');

let options =  {
  up: {
    delay: 300,
    interval: 300,
    origin: 'bottom',
    distance: '10rem',
  },
  down: {
    delay: 300,
    interval: 300,
    origin: 'top',
    distance: '10rem',
  },
  left: {
    delay: 300,
    interval: 300,
    origin: 'left',
    distance: '10rem',
  },
  right: {
    delay: 300,
    interval: 300,
    origin: 'right',
    distance: '10rem',
  }
};

if (window.parent.frames.length === 0) {
  ScrollReveal().reveal(heroElements, options.left);
  ScrollReveal().reveal(opportunity, options.up);
  ScrollReveal().reveal(whatWeDo, options.up);
  ScrollReveal().reveal(howWeDoIt, options.up);
  ScrollReveal().reveal(featuredInsights, options.up);
  ScrollReveal().reveal(testimonials, options.up);
  ScrollReveal().reveal(whyWeExist, options.up);
}
