var contact = document.querySelector('#contact');

if (contact !== null) {
  document.querySelectorAll('.toggle-inquiry').forEach((toggle) => {
    toggle.addEventListener('click', function (e) {
      e.preventDefault();
  
      contact.classList.toggle('active');
      document.querySelector('body').classList.toggle('frozen');
      document.querySelector('header').classList.toggle('darken');
    });
  });

  document.querySelector('.form .close').addEventListener('click', function (e) {
    e.preventDefault();

    contact.classList.toggle('active');
    document.querySelector('body').classList.toggle('frozen');
    document.querySelector('header').classList.toggle('darken');
  });

  document.querySelector('.success-message .btn').addEventListener('click', function (e) {
    e.preventDefault();

    contact.classList.toggle('active');
    document.querySelector('body').classList.toggle('frozen');
    document.querySelector('header').classList.toggle('darken');
  });

  if (document.querySelector('#hero-funnel .btn') && document.querySelector('#hero-funnel .btn').getAttribute("href").includes('#inquire')) {
    document.querySelector('#hero-funnel .btn').addEventListener('click', function (e) {
      e.preventDefault();

      contact.classList.toggle('active');
      document.querySelector('body').classList.toggle('frozen');
      document.querySelector('header').classList.toggle('darken');
    })
  }
}
